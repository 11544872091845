import { Link } from 'gatsby';
import { BiSearch } from 'react-icons/bi';
import styled from 'styled-components';
import { LinkButton } from '../../../../styles/Common';
import { deviceMax } from '../../../../styles/MediaQuery';
import {
    blue,
    citronBackground,
    containerWidth,
    greyLight,
    limaBackground,
    paragraphMargin,
    white,
} from '../../../../styles/Variables';

interface CardProps {
    readonly isProductSearch?: boolean;
}

export const Container = styled.div`
    max-width: ${containerWidth};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.25rem;
    margin-top: ${paragraphMargin};

    @media ${deviceMax.tabletM} {
        width: calc(100% - 30px);
    }

    @media ${deviceMax.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${deviceMax.mobileXl} {
        grid-template-columns: 1fr;
    }
`;

export const OverviewCard = styled.div<CardProps>`
    width: 100%;
    height: 205px;

    ${(props) =>
        props.isProductSearch
            ? `
        height: 165px;
        background-color: ${greyLight};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        @media ${deviceMax.tablet} {
            height: 185px;
        }

        @media ${deviceMax.mobileXl} {
            height: 210px;
        }
    `
            : `
        @media ${deviceMax.tablet} {
            height: 225px;
        }

        @media ${deviceMax.mobileXl} {
            height: 250px;
        }
    `}
`;

export const OverviewTitleBox = styled.div`
    background-color: ${greyLight};
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const OverviewTitle = styled(Link)`
    text-transform: uppercase;
    padding: 18px 20px 19px;
    text-decoration: none;
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    height: 15px;
    width: auto;
    font-family: 'NeueHelveticaPro57Condensed', Arial, Helvetica;

    &:hover {
        color: ${citronBackground};
    }
`;

export const OverviewImage = styled.img`
    width: 100%;
    height: calc(100% - 52px);
    object-fit: cover;
`;

export const SearchIcon = styled(BiSearch)`
    font-size: 1.5rem;
    border: 3px solid ${blue};
    border-radius: 50%;
    padding: 10px;
`;

export const RedirectionButton = styled(LinkButton)`
    background: transparent linear-gradient(${limaBackground}, ${citronBackground}) repeat scroll 0 0;
    color: ${white};
    border: none;
    margin: 0;
    text-transform: capitalize;
`;
