import styled from 'styled-components';
import { deviceMax } from '../../../../styles/MediaQuery';
import { blue, greyBorderButton, paragraphMargin, paragraphWidthEm, white } from '../../../../styles/Variables';

interface IsActiveProps {
    readonly isActive?: boolean;
}

export const Container = styled.div`
    width: ${paragraphWidthEm};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-top: ${paragraphMargin};
    min-height: 300px;

    @media ${deviceMax.tablet} {
        width: 90%;
    }

    @media ${deviceMax.tabletS} {
        padding: 0 10px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`;

export const MobileContainer = styled.div`
    border: 1px solid ${greyBorderButton};
    border-bottom: none;

    &:last-of-type {
        border-bottom: 1px solid ${greyBorderButton};
    }
`;

export const ListWrapper = styled.div`
    border: 1px solid ${greyBorderButton};
`;

export const ListItem = styled.div<IsActiveProps>`
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${greyBorderButton};
    position: relative;
    cursor: pointer;

    ${(props) =>
        props.isActive &&
        `
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -20px;
            width: 0;
            height: 0;
            border-left: 20px solid ${white};
            border-bottom: 30px solid transparent;
            border-top: 30px solid transparent;
        }
    `}

    &:last-of-type {
        border-bottom: none;
    }

    * {
        padding: 0;
        margin: 0;
    }

    @media ${deviceMax.tabletS} {
        border: none;
        ${(props) =>
            props.isActive &&
            `
        &:after {
            content: '';
            position: absolute;
            top: 100%;
            right: calc(50% - 32px);
            width: 0;
            height: 0;
            border-left: 40px solid transparent;
            border-top: 25px solid ${white};
            border-right: 40px solid transparent;
        }
    `}
    }
`;

export const ItemTitle = styled.h4`
    text-transform: uppercase;
    line-height: 1.25rem;
    font-weight: 400;
    font-size: 15px;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
    display: flex;
    align-items: center;
`;

export const ItemNumber = styled.span`
    color: ${blue};
    font-size: 1.5rem;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
    line-height: 1.75rem;
    margin-right: 10px;
`;

export const ContentWrapper = styled.div<IsActiveProps>`
    background: ${greyBorderButton};
    padding: 50px 25px 25px 35px;

    @media ${deviceMax.tabletS} {
        padding: ${(props) => (props.isActive ? '30px 5px' : '0')};
        height: ${(props) => (props.isActive ? 'auto' : '0')};
        transition: height 0.4s;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
