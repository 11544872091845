import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa';
import styled from 'styled-components';
import iconList from '../../../../static/images/tick.png';
import { SmallContainerColumn } from '../../../../styles/Common';
import { deviceMax } from '../../../../styles/MediaQuery';
import {
    black,
    blue,
    darkGreen,
    greyLight,
    lightGraishButton,
    lightGray,
    lightGrayishBlue,
    lightGreyButton,
    linkOnHover,
    paragraphMargin,
    paragraphWidthEm,
} from '../../../../styles/Variables';

export const BackgroundContainer = styled.div`
    background: ${greyLight};
    width: 100%;
    margin-top: ${paragraphMargin} !important;
    padding-bottom: ${paragraphMargin};
`;

export const Container = styled(SmallContainerColumn)`
    justify-content: center;
    align-items: flex-start;
    width: ${paragraphWidthEm};
    margin: ${paragraphMargin} auto 0 auto;

    h2:only-child {
        margin-bottom: -${paragraphMargin};
    }

    a {
        text-decoration: none;
        color: ${darkGreen};

        :hover {
            color: ${linkOnHover};
            text-decoration: none;
        }
    }

    @media ${deviceMax.tablet} {
        width: 90%;
    }
`;

export const Title = styled.h2`
    font-family: 'NeueHelveticaPro57Condensed', Arial, Helvetica;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${blue};
    margin: 0.5rem 0;
    letter-spacing: 0.025em;
`;

export const Subtitle = styled.h3`
    font-family: 'NeueHelveticaPro57Condensed', Arial, Helvetica;
    font-size: 15px;
    text-transform: uppercase;
    margin: 12px 0 7px 0;
    letter-spacing: 0.025em;
`;

export const ContentBox = styled.div`
    gap: 1em;
    width: 100%;
    position: relative;

    @media ${deviceMax.tablet} {
        display: flex;
        flex-direction: column;
    }

    * {
        font-family: Arial, Helvetica;
        color: ${black};
    }
`;

interface ImageProps {
    readonly float: string;
}

export const Image = styled.img<ImageProps>`
    min-width: 140px;
    width: ${(props) => (props.float === 'left' || props.float === 'right' ? '300px' : '100%')};
    height: 150px;
    flex: 1;
    float: ${(props) => props.float};
    margin-right: 1.5em;
    padding-bottom: 1.525em;
    object-fit: cover;

    @media ${deviceMax.tablet} {
        max-width: unset;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        max-height: 320px;
    }
`;

interface TextProps {
    readonly float?: string;
}

export const TextWrapper = styled.div`
    flex: 1;
`;

export const Text = styled.div<TextProps>`
    line-height: 1.25em;

    * {
        margin-top: 0;
        font-size: 0.875rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    iframe {
        max-width: 100%;
        width: 100%;
    }

    a.cmn_btn.txt_btn {
        display: inline-block;
        min-width: 150px;
        font-size: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
        background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
        color: ${darkGreen};
        border: 1px solid ${lightGray};
        padding: 14px 30px;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 15px;
        text-decoration: none;
        font-family: Arial, Helvetica;
        box-sizing: border-box;

        &:hover {
            background: ${lightGraishButton};
        }
    }

    ul {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: table;
            line-height: 1.5rem;
            word-break: break-word;
            padding: 0 0 0 22px;
            background: url(${iconList}) no-repeat 0% 4px;
            background-size: 15px 15px;
            vertical-align: top;
            text-align: left;
            font-size: 0.875rem;
            margin: 8px 0 0 0;

            ::marker {
                color: ${blue};
                font-size: 1.25rem;
                font-weight: 700;
            }

            a {
                text-decoration: none;
                color: ${darkGreen};

                :hover {
                    color: ${linkOnHover};
                    text-decoration: none;
                }
            }
        }

        @media ${deviceMax.tablet} {
            right: 0;
        }
    }

    .mce_text > p {
        margin-bottom: 0.5rem;
    }

    .itemname {
        color: black;
    }

    & img {
        width: 100%;
        max-height: 320px;
        object-fit: cover;
    }

    @media ${deviceMax.tablet} {
        .layout_form {
            * {
                box-sizing: border-box;
            }
        }
    }
`;

export const Button = styled.button`
    font-size: 0.875rem;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    color: ${darkGreen};
    border: 1px solid ${lightGray};
    min-width: 9.4em;
    padding: 0.75em 1.875em;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background: ${lightGrayishBlue};
    }

    * {
        color: ${darkGreen};
    }
`;

export const UpIcon = styled(FaAngleDoubleUp)`
    margin-bottom: -0.3em;
    margin-left: 0.2em;
    font-size: 0.9em;
`;

export const DownIcon = styled(FaAngleDoubleDown)`
    margin-bottom: -0.3em;
    margin-left: 0.2em;
    font-size: 0.9em;
`;
