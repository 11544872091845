import styled from 'styled-components';
import { LinkButton } from '../../../../styles/Common';
import { deviceMax } from '../../../../styles/MediaQuery';
import { containerWidth, darkGreen, lightGrayishBlue, paragraphMargin, white } from '../../../../styles/Variables';

export const HIGHLIGHTED_MEDIA_BREAKPOINT = 550;
interface HighlightedContainerProps {
    readonly maxHeight: number;
}

export const HighlightedContainer = styled.div<HighlightedContainerProps>`
    width: ${containerWidth};
    height: ${(props) => `${props.maxHeight}px`};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
    grid-gap: 1.25rem;
    margin-top: ${paragraphMargin};
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    @media (max-width: ${HIGHLIGHTED_MEDIA_BREAKPOINT}px) {
        grid-template-columns: 1fr;
        height: 490px;
    }

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

interface HighlightedItemProps {
    readonly position: number;
    readonly lastActiveDesktop: number;
    readonly lastActiveMobile: number;
}

export const HighlightedItem = styled.div<HighlightedItemProps>`
    width: calc(100% / 2 - 10px);
    height: 100%;
    padding: 1.25rem;
    background: linear-gradient(135deg, #1e5799 0%, #2989d8 100%, #207cca 100%, #7db9e8 100%, #2989d8 101%);
    position: absolute;
    top: 0;
    transition: 0.4s;
    box-sizing: border-box;
    left: ${(props) => {
        if (props.position === props.lastActiveDesktop) {
            return `calc(50% + 10px)`;
        } else if (props.position === props.lastActiveDesktop - 1) {
            return '0';
        } else if (props.position < props.lastActiveDesktop - 1) {
            return '-100%';
        } else {
            return '105%';
        }
    }};

    @media (max-width: ${HIGHLIGHTED_MEDIA_BREAKPOINT}px) {
        width: 100%;
        left: ${(props) => {
            if (props.position === props.lastActiveMobile) {
                return '0px';
            } else if (props.position < props.lastActiveMobile) {
                return '-100%';
            } else {
                return '105%';
            }
        }};
    }

    * {
        color: ${white};
    }
`;

export const HighlightedItemTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: 0.03rem;
    padding: 10px 0 0 0;
    margin: 0;
    font-family: 'NeueHelveticaPro77CondensedBold', Arial, Helvetica;
`;

export const HighlightedItemText = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-bottom: 4rem;
`;

export const HighlightedItemButton = styled(LinkButton)`
    position: absolute;
    bottom: 0;
    margin: 20px 0;
    color: ${darkGreen};
    height: 14px;
    padding: 13px 32px;

    &:hover {
        background: none;
        background-color: ${lightGrayishBlue};
    }
`;
