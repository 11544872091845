import styled from 'styled-components';
import {
    CommonBullet,
    CommonBullets,
    ContainerColumnStartWithoutWidth,
    SmallContainerColumn,
} from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';
import { midnightBlue, paragraphMargin, paragraphWidthRem, white } from '../../../styles/Variables';

export const SliderContainer = styled(ContainerColumnStartWithoutWidth)`
    width: ${paragraphWidthRem};
    margin-top: ${paragraphMargin};

    @media ${deviceMax.tablet} {
        width: 90%;
    }
`;

interface SlidesContainerProps {
    readonly sliderHeight: number;
}

export const SlidesContainer = styled(SmallContainerColumn)<SlidesContainerProps>`
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: ${({ sliderHeight }) => `${sliderHeight}px`};
`;

interface SlideProps {
    readonly isActive: boolean;
}

export const SlideContainer = styled(ContainerColumnStartWithoutWidth)<SlideProps>`
    width: 100%;
    transition: 0.4s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
`;

export const SectionWrapper = styled.div`
    min-width: 50%;
`;

export const TextContainer = styled.div`
    background-color: ${midnightBlue};
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;

    * {
        color: ${white};
    }
`;

export const TextContainerTitle = styled.h4`
    font-size: 0.9375rem;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin: 1.5rem 1rem 0.5rem 1rem;
`;

export const TextContainerContent = styled.div`
    margin: 0 1rem 1rem 1rem;
    font-size: 0.9375rem;
    font-family: Arial, Helvetica, sans-serif;
    * {
        font-size: 0.9375rem;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.2rem;
        margin: 0;
        padding: 0;
        word-break: break-word;
    }
`;

export const SlideImageWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
`;

export const SlideImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const Bullets = styled(CommonBullets)`
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0.5rem;
    margin: 0;
`;

export const Bullet = styled(CommonBullet)``;
