import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { deviceMax, deviceMin } from '../../../../styles/MediaQuery';
import { blue, blueDark, containerWidth, greyBorderButton, paragraphMargin } from '../../../../styles/Variables';

export const Container = styled.div`
    height: 560px;
    margin: 0 auto;
    position: relative;
    width: ${containerWidth};
    max-width: ${containerWidth};
    margin-top: ${paragraphMargin};

    @media ${deviceMax.laptop} {
        padding: 0 15px;
        width: calc(100% - 30px);
        height: unset;
        margin-bottom: 1em;
    }
`;

interface ServiceItems {
    readonly numberOfItems?: number;
}

const serviceSize = 35;
const additionalSize = 45;

export const Circle = styled.div<ServiceItems>`
    border-radius: 197px;
    border: #e8e8e8 1px solid;
    margin: 0 auto;
    margin-top: 5rem;
    position: relative;
    width: ${(props) => `${props.numberOfItems * serviceSize + additionalSize}px`};
    height: ${(props) => `${props.numberOfItems * serviceSize + additionalSize}px`};

    @media ${deviceMax.laptop} {
        display: none;
    }
`;

export const ServiceList = styled.ul`
    list-style: none;

    @media ${deviceMax.laptop} {
        display: none;
    }
`;

export const ImageWrapper = styled.div`
    background-color: #0571b9;
    height: 74px;
    width: 74px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    img {
        max-width: 100%;
    }

    @media ${deviceMax.laptop} {
        position: static;
        height: 40px;
        width: 40px;
        margin-right: 20px;
    }
`;
export const ServiceLink = styled(Link)`
    cursor: pointer;
`;

interface ServiceLinkTextProps {
    readonly isBorderVisible?: boolean;
}

export const ServiceLinkText = styled.span<ServiceLinkTextProps>`
    position: absolute;
    border-bottom: ${(props) => (props.isBorderVisible ? `${greyBorderButton} 1px solid` : ``)};
    color: ${blue};
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
    font-size: 15px;
    height: 85px;
    line-height: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: 700;

    @media ${deviceMax.laptop} {
        position: static;
        border-bottom: 0;
        height: unset;
    }
`;

const degrees_to_radians = (degrees: number): number => degrees * (Math.PI / 180);

type Coordinates = 'x' | 'y';
const calculateCoordinates = (pointAngleInRadians: number, radius: number, coordinate: Coordinates): number =>
    coordinate === 'x' ? Math.cos(pointAngleInRadians) * radius : Math.sin(pointAngleInRadians) * radius;

const createServiceChilds = (services) => {
    let styles = '';
    let leftImage: number;
    let topImage: number;
    let radius = (services * serviceSize + additionalSize) / 2;
    const maxTextWidth = 297;
    const minTextWidth = 160;
    let minMaxWidthDiff = maxTextWidth - minTextWidth;
    let textWidth: number;
    const leftShift = 430;
    const topShift = 46;

    for (let i = 1; i <= services; i++) {
        leftImage = leftShift + calculateCoordinates(degrees_to_radians(270 + (360 / services) * (i - 1)), radius, 'x');
        topImage =
            topShift +
            (services * serviceSize + additionalSize) / 2 +
            calculateCoordinates(degrees_to_radians(270 + (360 / services) * (i - 1)), radius, 'y');
        textWidth = (Math.abs(topShift + radius - topImage) / (topShift + radius)) * minMaxWidthDiff + minTextWidth;

        styles += `
            &:nth-child(${i}) {
                ${ImageWrapper} {
                    left: ${leftImage}px;
                    top: ${topImage}px;
                }
                ${ServiceLinkText} {
                    ${
                        leftImage > leftShift + calculateCoordinates(degrees_to_radians(270), radius, 'x')
                            ? `right: 0; text-align: right; display: flex;
                            justify-content: right;`
                            : `left: 0;`
                    }
                    top: ${i === 1 ? -10 : services % 2 === 0 && i === services / 2 + 1 ? topImage + 30 : topImage}px;
                    width: ${textWidth}px;
                }
            }
            &:hover {
                ${ImageWrapper} {
                    background-color: ${blueDark};
                }
                ${ServiceLinkText} {
                    color: ${blueDark};
                }
            }
        `;
    }

    return css`
        ${styles}
    `;
};

export const Service = styled.li<ServiceItems>`
    ${(props) => createServiceChilds(props.numberOfItems)};
`;

export const ServiceListMobile = styled.ul`
    list-style: none;
    padding-left: 0px;

    @media ${deviceMin.laptop} {
        display: none;
    }
`;

export const ServiceMobile = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &:hover {
        ${ImageWrapper} {
            background-color: ${blueDark};
        }
        ${ServiceLinkText} {
            color: ${blueDark};
        }
    }

    @media ${deviceMin.laptop} {
        display: none;
    }
`;
