import { Link } from 'gatsby';
import { FaAngleDoubleDown } from 'react-icons/fa';
import styled from 'styled-components';
import { SectionTitle, SmallContainerColumn } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';
import {
    blue,
    containerWidth,
    darkGreen,
    greenPrintDarker,
    greyLight,
    lightGraishButton,
    lightGray,
    lightGreyButton,
    paragraphWidthEm,
    paragraphWidthRem,
} from '../../../styles/Variables';

export const Container = styled.div`
    max-width: ${containerWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${deviceMax.tablet} {
        width: 90%;
        right: 0;
    }
`;

export const ProjectInfoBox = styled.div`
    width: ${paragraphWidthEm};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media ${deviceMax.tablet} {
        width: 100%;
        right: 0;
    }
`;

export const ProjectInfoBoxHeadline = styled.h3`
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'NeueHelveticaPro77CondensedBold', Arial, Helvetica;
    margin-bottom: 0;
    margin-top: 2.5rem;
`;

export const Subtitle = styled.h2`
    color: ${blue};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75rem;
    font-family: 'NeueHelveticaPro77CondensedBold', Arial, Helvetica;
    margin-top: 0.5rem;
`;

export const BodyItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    right: 10%;

    @media ${deviceMax.tablet} {
        right: 0;
    }

    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        border: 2px solid ${blue};
        border-radius: 50%;
        padding: 0.5rem;

        * {
            font-size: 1.5rem;
        }
    }
`;

export const Button = styled.button`
    font-size: 1rem;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    color: ${darkGreen};
    border: 1px solid ${lightGray};
    min-width: 9.4em;
    padding: 0.75em 1.875em;
    border-radius: 5px;
    cursor: pointer;
    margin: 2rem 0;
    font-family: Arial, Helvetica, sans-serif;
`;

export const DownIcon = styled(FaAngleDoubleDown)`
    * {
        color: ${darkGreen};
    }
`;

export const DescriptionContainer = styled.div`
    width: 100%;
    padding: 0;
    margin: 0;
    background: ${greyLight};
    padding-bottom: 1.25rem;
    margin-bottom: 2.5rem;
    * {
        font-size: 0.875rem;
        line-height: 20px;
        font-family: Arial, Helvetica, sans-serif;
    }

    a {
        color: ${darkGreen};
    }
`;

export const IconsBox = styled.div`
    max-width: ${paragraphWidthRem};
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 2rem;

    @media ${deviceMax.tablet} {
        right: 0;
        padding: 0 1rem;
    }
`;

export const DescIcon = styled.span`
    border: 2px solid ${blue};
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 50%;
    right: 10%;
    position: relative;

    * {
        font-size: 1.5rem;
    }

    @media ${deviceMax.tablet} {
        right: 3%;
    }
`;

export const CloseIcon = styled.span`
    cursor: pointer;
    * {
        color: ${darkGreen};
        font-size: 1.5rem;
    }
`;

export const DescriptionContent = styled.div`
    max-width: ${containerWidth};
    margin: 0 auto;

    div {
        max-width: ${paragraphWidthRem};
        margin: 0 auto;

        @media ${deviceMax.tablet} {
            max-width: 66%;
        }
    }
`;

export const ProductsListContainer = styled(SmallContainerColumn)`
    margin: 0 auto;
    width: ${paragraphWidthEm};
    margin-bottom: 4rem;

    @media ${deviceMax.tablet} {
        width: 90%;
    }
`;

export const ProductsListTitle = styled(SectionTitle)`
    color: ${blue};
`;

export const ProductsListItem = styled(Link)`
    color: ${darkGreen};
    font-size: 0.875rem;
    line-height: 1.4em;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        color: ${greenPrintDarker};
    }
`;
