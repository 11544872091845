import styled from 'styled-components';
import { SmallContainerColumn } from '../../../../styles/Common';
import { deviceMax } from '../../../../styles/MediaQuery';
import {
    blue,
    darkGreen,
    greyBorder,
    linkOnHover,
    paragraphMargin,
    paragraphWidthEm,
} from '../../../../styles/Variables';

export const Container = styled(SmallContainerColumn)`
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    margin-top: ${paragraphMargin};
    width: ${paragraphWidthEm};

    a {
        text-decoration: none;
        color: ${darkGreen};

        :hover {
            color: ${linkOnHover};
            text-decoration: none;
        }
    }

    @media ${deviceMax.tablet} {
        width: 90%;
    }
`;

export const Title = styled.h2`
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${blue};
    margin-bottom: 0;
    border-bottom: 1px solid ${greyBorder};
    font-family: 'NeueHelveticaPro57Condensed', Arial, Helvetica;
    letter-spacing: 0.01em;
`;
